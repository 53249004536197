.files-uploader {
  margin-top: 10px;
  color: var(--font-dark);
}

.files-dropzone {
  padding: 30px;
  margin: 10px 0;
  background-color: #fff;
  border-radius: 3px;
  text-align: center;
}

.files-dropzone-active {
  background-color: var(--green);
}

.files__list {
  margin: 10px 0;
  max-height: 300px;
  overflow: auto;
}

.files__file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 5px;
}

.files__close {
  display: inline-flex;
  cursor: pointer;
}
