.module-labels-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border: 1px solid var(--light-bg);
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  border-radius: 10px;
  background-color: #fff;
}

.module-labels-list__content {
  max-height: 300px;
  overflow: auto;
}

.module-labels-list__title {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  background-color: #e8e8e8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.module-labels-list .input {
  padding: 5px;
}

.module-labels-list .input input {
  padding: 5px;
  border: 1px solid var(--light-bg);
}

.module-labels-list .input.focused input {
  border: 1px solid var(--dark-bg);
}

.module-labels-list__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 20px;
  padding: 10px 0;
  font-size: 15px;
  color: var(--font-dark);
  border-top: 1px solid var(--light-bg);
  cursor: pointer;
}

.module-labels-list__item:hover {
  background-color: var(--lighter-bg);
}

.module-labels-list__item--no-hover:hover {
  cursor: auto;
  background-color: none;
}

.module-labels-list__item .icon {
  width: 15px;
  margin: 0 5px;
}

.module-labels-list__item--edit {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.module-labels-list__item--edit .icon {
  margin-left: 25px;
}

.module-labels-list__item--center {
  justify-content: center;
  min-height: 0;
}

.module-labels-list__color {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin-right: 5px;
}

.module-labels-list__name {
  white-space: nowrap;
  max-width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
}
