.editable-text {
  display: flex;
  flex-direction: column;
}

.editable-text__input {
  display: flex;
  align-items: center;
  cursor: text;
}

.editable-text__content {
  margin-right: 15px;
}

.editable-text__content::before {
  content: attr(data-placeholder);
  color: gray;
}

.editable-text__controls {
  display: flex;
  margin-right: 15px;
}

.editable-text__controls .disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.editable-text__controls .icon {
  cursor: pointer;
  margin-right: 6px;
}

.editable-text__errors {
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0;
  padding: 10px;
  color: #fff;
  background-color: var(--danger);
  border-radius: 11px;
}

.editable-text__error {
  margin: 5px 0;
}
