.terms__label {
  margin-bottom: 5px;
  width: 100%;
}

.terms__button .icon {
  margin-right: 8px;
}

.terms__title {
  display: block;
  margin-bottom: 3px;
}
