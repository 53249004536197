.notifications {
  flex-direction: column;
  align-items: flex-start;
}

.notifications__delete-all{
  margin-bottom: 20px;
}

.notificationsList {
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
}

.notificationsList__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.notificationsList__item:hover .notification__content{
 font-weight: 700;
}

.notificationsList .notification {
  cursor: pointer;
}

.notificationsList .notification__content {
  align-items: center;
}

.notificationsList .notification__who {
  margin-top: 0;
}

.notificationsList .notification__action {
  margin-bottom: 0;
}

.notificationsList .notification__date {
  display: inline-block;
  margin-top: 5px;
}
