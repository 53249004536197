.section--wrapper {
  position: relative;
  /* flex: 1; */
  height: 100%;
  margin: 0 7.5px;
  border-bottom: none;
  overflow: auto;
}

.section--wrapper:first-child {
  margin-left: 0;
}

.section--wrapper:last-child.module-details {
  margin-right: 0;
  max-width: 30vw;  
}

.section__content {
  padding-top: 46px;
  display: flex;
  /* 100% - 46px padding-top - height of header - margin-top for header */
  height: calc(100% - 46px - var(--header-height) - var(--header-margin-top));
}

.section__half {
  display: inline-block;
  width: 50%;
  margin: 0 15px;
}

.section__half:first-child {
  margin-left: 0;
}

.section__half:last-child {
  margin-right: 0;
}

.section__heading {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--font-dark);
  margin-bottom: 10px;
}
