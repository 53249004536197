.modulesList {
  width: 100%;
  color: var(--font-dark);
}

.modulesList__no-results {
  margin-top: 20px;
  max-height: 300px;
  overflow: auto;
  border: 1px solid var(--dark-bg);
  background-color: #fff;
  padding: 10px;
}

.modulesList .limitedui {
  display: contents;
}

.modulesList__new {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.modulesList__new .files-uploader {
  margin-top: 0;
}

.modulesList__top-bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.modulesList__top-bar .input {
  width: 100%;
}

.modulesList__top-bar .react-select {
  max-width: 50%;
  margin: 0;
}

.modulesList__top-bar input[type="submit"] {
  flex: 1;
  height: 38px;
}

.modulesList__new input, .modulesList__new .react-select {
  margin-right: 10px;
  min-width: 100px;
}

.modulesList .book-selector {
  margin: 10px 0 -10px 0;
}

.modulesList__list {
  margin-top: 20px;
  max-height: 250px;
  overflow: auto;
  border: 1px solid var(--dark-bg);
  background-color: #fff;
}

.modulesList__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-bg);
}

.modulesList__item:hover {
  color: var(--font-light);
  background-color: var(--dark-bg);
}

.modulesList__item > span {
  flex: 1;
}

.modulesList__item > .button {
  margin-right: 10px;
}

.modulesList__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  line-height: 1.2;
}
