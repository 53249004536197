.target {
  padding: 8px;
  margin: 4px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 3px;
}

.target:hover {
  box-shadow: 0 2px 4px 2px rgba(51, 51, 51, 0.4);
}
