.helpdesk__tools {
  display: flex;
  flex-direction: column;
}

.helpdesk__tools .button {
  margin-bottom: 20px;
}

.helpdesk__dialog-content .dialog__buttons {
  margin-top: 20px;
}

.helpdesk .tickets {
  height: calc(100% - 126px);
}
