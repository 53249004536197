@media print {
  /* --- remove print-unfriendly colouring ---------------------------------- */

  body {
    background-color: unset !important;
  }

  .document__editor > .editor {
    background: unset !important;
    box-shadow: unset !important;
  }

  /* --- hide navigation elements ------------------------------------------- */

  aside.sidebar, .section--wrapper > .header {
    display: none !important;
  }

  /* --- remove dynamic layout ---------------------------------------------- */

  body {
    position: unset !important;
  }

  html, body, #root, .container, main, section {
    width: unset !important;
    height: unset !important;
    max-width: unset !important;
    max-height: unset !important;
  }

  main, section {
    margin: unset !important;
  }

  /* Firefox currently can't split pages inside a flex box, so for now replace
   * them with other layouts where possible.
   *
   * See https://bugzilla.mozilla.org/show_bug.cgi?id=939897
   */
  .container,
  main,
  .section__content,
  .draft__editor,
  .document,
  .document__editor,
  .document__editor figure {
    display: block !important;
  }

  .nestable-collapsed {
    display: block !important;
  }

  .document__header {
    width: unset !important;
    padding: unset !important;
    margin-left: unset !important;
    margin-right: unset !important;
  }

  .document__editor > .editor {
    width: unset !important;
    max-width: unset !important;
    margin: unset !important;
    padding: unset !important;
  }

  /* --- hide interactive-only elements ------------------------------------- */

  .process-selector,
  .bookpart__icon,
  .bookpart__info > button,
  .bookpart__info > .module-labels > .tooltip,
  .limitedui,
  .document__editor > .toolbox,
  .document__glossary-toggler {
    display: none !important;
  }

  /* --- adjustments to the editor ------------------------------------------ */

  /* Avoid page breaks on images.
   *
   * Unfortunately we can't directly target the image container, as it's being
   * generated by Slate and we can't mark them in any way. While this targets
   * more than just images it should still be safe to do, as all other void
   * nodes in our schema are inline.
   */
  [data-slate-void="true"] {
    break-inside: avoid;
  }
}
