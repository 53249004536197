.import {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.resolution-selector {
  overflow-y: auto;
}

.resolution-selector__items {
  display: grid;
  gap: 8px;
  grid-template-columns: min-content 1fr;
}

.resolution-item {
  display: contents;
}

.resolution-item__kind {
  grid-column: 1;
}

.resolution-item__resolution {
  grid-column: 2
}

.resolution-item__resolution label {
  display: block;
}
