.settings {
  width: 100%;
  max-width: 400px;
}

.settings .input, .react-select {
  width: 100%;
  margin-bottom: 10px;
}

.settings--subsection {
  margin-bottom: 40px;
}

.settings__title {
  font-size: 1rem;
  font-weight: 700;
  margin: 20px 0;
}

.settings__title--large {
  font-size: 1.3rem;
  margin: 20px 0 30px 0;
}

.settings__title:first-child {
  margin-top: 0;
}
