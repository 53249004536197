.teams__member-add {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.teams__member-add .react-select {
  margin-bottom: 0;
  margin-right: 10px;
  max-width: 250px;
}
