.process-slot {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: var(--lighter-bg);
}

.process-slot .button .icon {
  margin-right: 6px;
}

.process-slot > .process-form__title .process-form__title-controls {
  opacity: 0;
}

.process-slot:hover > .process-form__title .process-form__title-controls {
  opacity: 1;
}

.process-slot__role {
  opacity: 0.6;
  margin-right: 10px;
  margin-top: 10px;
}

.process-slot__role.selected {
  opacity: 1;
}
