.assetList__controls {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--dark-bg);
  border-radius: 6px;
}

.assetList__controls .search-input {
  width: 300px;
}

.assetList__controls .button {
  margin-left: 10px;
}

.assetList__controls .button.active {
  color: var(--font-dark);
  background-color: #fff;
}

.assetList__list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow-y: auto;
}

.assetList__item .button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 250px;
  height: 120px;
  margin: 8px;
  font-size: 0.9rem;
}

.assetList__item .button .icon {
  margin-bottom: 10px;
}

.assetList__item .asset__image {
  width: 250px;
  height: 150px;
}
