.tickets__sorter {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.tickets__sorter .button {
  margin-left: 5px;
  height: 34px;
  min-width: auto;
}

.tickets__sorter .button .icon {
  width: 20px;
}

.tickets__list {
  border: 1px solid var(--dark-bg);
}

.tickets__list-item {
  border-bottom: 1px solid var(--dark-bg);
}

.tickets__list-item--no-tickets {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 30px;
  background-color: #fff;
}

.tickets__list-item:last-child {
  border-bottom: none;
}
