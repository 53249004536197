.process-form {
  padding: 20px;
  background-color: #fff;
}

.process-form .controls {
  margin-bottom: 20px;
}

.process-form .controls .button {
  margin-right: 10px;
}

.process-form .controls .button .icon {
  margin-right: 6px;
}

.process-form__errors {
  padding: 10px;
  border: 1px solid var(--red);
  border-radius: 5px;
  margin-bottom: 10px;
}

.process-form__errors li {
  margin-bottom: 10px;
}

.process-form__errors li:last-child {
  margin-bottom: 0;
}

.process-form .process-form__split {
  display: flex;
  margin-top: 20px;
}

.process-form h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.process-form__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.process-form__title .button .icon {
  margin: 0;
}

.process-form label {
  display: block;
  padding: 5px;
  background-color: var(--lighter-bg);
}

.process-form label h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

.process-form label .input, .process-form label .react-select {
  margin-bottom: 0;
}

.process-form__select-team-message {
  margin-top: 20px;
  text-align: center;
}
