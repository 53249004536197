.asset {
  margin: 8px;
  min-width: 180px;
  max-width: 360px;
  max-height: 160px;
  flex: 1;
}

.asset:hover {
  cursor: pointer;
  box-shadow: 0 2px 4px 2px rgba(51, 51, 51, 0.4);
}

.asset {
  position: relative;
}

.asset__image {
  display: block;
  width: 100%;
  height: auto;
  max-height: 120px;
  object-fit: cover;
}

.asset__name {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  overflow: hidden;
  padding: 8px 12px;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  white-space: pre;
  background-color: rgba(255, 255, 255, 0.8);
}

.asset .icon {
  width: 100%;
  margin-top: 5px;
}

.asset--with-icon {
  height: 120px;
}

.asset--with-icon .asset__mime {
  display: none;
}
