.math-editor {
    width: 100%;
    height: 100%;
    background-color: #fff;

    display: flex;
    flex-direction: column;
}

.math-editor math-field {
    font-size: 26px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow-y: auto;
}

.math-editor__tools {
    display: flex;
    flex-direction: column;
}

.math-editor__toolbar {
    display: flex;
    justify-content: flex-end;
    padding: 4px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.math-editor__toolbar .button {
    margin-left: 4px;
}

.math-editor__snippets {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: 50vh;
    overflow-x: auto;
}

.snippet-group {
    padding: 10px;
}

.snippet-group__header {
    font-size: 16px;
    font-weight: 700;
    padding-left: 8px;
    padding-right: 8px;
}

.snippet-group__content {
    display: grid;
    grid-template-columns: repeat(5, minmax(min-content, 1fr));
}

.snippet {
    position: relative;
    display: inline;
}

.snippet.has-variants::after {
    position: absolute;
    top: 0;
    right: 0;
    content: '+';
}

.snippet * {
    cursor: pointer;
}

.snippet:hover .snippet__palette {
    display: flex;
}

.snippet__palette {
    display: none;
    width: 200px;
    flex-wrap: wrap;

    position: absolute;
    left: -1px;
    top: -1px;
    z-index: 9999999;

    background: white;
    box-shadow: 0px 0px 2px gray;
    border-radius: 2px;

    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;

    transition: unset;
}
