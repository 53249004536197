.error404 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.error404 h1 {
  font-size: 1.3rem;
}
