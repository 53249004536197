.profile {
  width: 100%;
}

.profile .userui {
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
}

.profile__avatar {
  position: relative;
}

.profile__avatar .userui {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-left: 0;
}

.profile__top {
  display: flex;
  align-items: center;
}

.profile__name {
  font-size: 1.4rem;
  font-weight: 700;
}

.profile__main-info {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.profile__roles {
  display: flex;
  flex-direction: column;
}

.profile__role {
  margin: 10px 0;
}

.profile__role-name {
  display: block;
  margin-bottom: 10px;
}

.profile__main-info .limitedui .react-select {
  margin-top: 15px;
}

.profile__title {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-top: 30px;
}

.profile__select {
  min-width: 200px;
}

.profile__bio p {
  line-height: 1.4;
  margin-bottom: 10px;
}

.profile__update-dialog button {
  margin-top: 10px;
}

.profile__flags {
  margin: 20px 0;
}
