.highlight {
  position: relative;
  padding: 0 2px;
  opacity: 0.8;
}

.highlight--lightblue {
  background-color: #37abc8;
}

.highlight--green {
  background-color: #37c8ab;
}

.highlight--red {
  background-color: #ff5555;
}

.highlight--yellow {
  background-color: #ffeb55;
}
