.conversation-tabs {
  display: flex;
  position: absolute;
  z-index: 99;
  left: 0;
  bottom: 0;
  height: 35px;
}

.conversation-tabs .conversation-tab {
  margin-right: 15px;
}

.hidden-tabs {
  position: relative;
}

.hidden-tabs__toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid var(--dark-bg);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.hidden-tabs__toggler:hover {
  background-color: var(--lighter-bg);
}

.hidden-tabs__list {
  position: absolute;
  bottom: 35px;
  display: flex;
  flex-direction: column;
  width: 250px;
  border: 1px solid var(--dark-bg);
  border-radius: 5px;
  background-color: #fff;
}

.hidden-tabs__tab {
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid var(--lighter-bg);
}

.hidden-tabs__tab:last-child {
  border-bottom: none;
}

.hidden-tabs__tab:hover {
  background-color: var(--lighter-bg);
}
