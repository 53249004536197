.teams__role-add .permissions {
  margin-top: 10px;
  background-color: #fff;
}

.teams__role-add {
  margin: 10px 0;
}

.teams__role-add-input {
  display: flex;
  align-items: center;
}

.teams__role-add .input {
  margin-bottom: 0;
  flex: 1;
}

.teams__role-add input[type="submit"] {
  margin-left: 10px;
  margin-right: 0;
}
