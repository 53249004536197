.button {
  /* reset button styling */
  display: inline-flex;
  justify-content: center;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  text-decoration: none;
  /* end of reset */

  font-size: 16px;
  padding: 7px 20px;
  color: var(--font-light);
  min-width: 75px;
  background-color: var(--dark-bg);
  cursor: pointer;
  border-radius: 17px;
  outline: none !important;
}

.button.active {
  background-color: var(--darkest-bg);
}

.button--borderd {
  border: 2px solid #fff;
  padding: 5px 20px;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.button--default {
  background-color: var(--dark-bg);
}

.button--danger {
  background-color: var(--danger);
}

.button--disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.button > .spinner-holder {
  padding-left: 8px;
}

.button > .spinner-holder .spinner {
  margin: 0;
  width: 10px;
  height: 10px;
  border: 5px solid transparent;
  border-top: 5px solid var(--main-decoration);
  border-bottom: 5px solid var(--secondary-decoration);
}
