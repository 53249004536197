.tickets__manager {
  height: 100%;
}

.tickets__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  background-color: #fff;
}

.tickets__constrols--group {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.tickets__constrols--group .button {
  min-width: auto;
}

.tickets__controls .button {
  margin-right: 10px;
}

.tickets__info {
  padding: 20px;
}
