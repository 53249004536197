@import 'mathlive/dist/mathlive-fonts.css';

.math {
    text-align: center;
    transition: none;
}

.math[data-selected=false] {
    padding: 1px;
}

.math[data-selected=true] {
    border: 1px solid lightblue;
}

.editor .math math-field {
    pointer-events: none;
}

.displayInline {
    display: inline-block;
}
