.manage-team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.manage-team .input {
  flex: 1;
  margin-bottom: 0;
}

.manage-team input[type="submit"] {
  margin-left: 10px;
  margin-right: 0;
}
