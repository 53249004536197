.modules__list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 800px;
  background-color: #fff;
}

.modules__item {
  display: flex;
  align-items: center;
  padding: 15px;
}

.modules__item.active {
  font-weight: 700;
}
