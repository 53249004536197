.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.modal__close {
  position: absolute;
  z-index: 2;
  min-width: 0;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
}

.modal__close .icon {
  background-color: var(--light-bg);
  border-radius: 50%;
}

.modal__close .icon svg {
  fill: var(--danger);
}

.modal__content {
  position: relative;
  padding: 20px;
  margin-top: 30px;
  max-width: 80%;
  max-height: calc(100% - 80px);
  font-size: 1rem;
  background-color: var(--light-bg);
  border-radius: 17px;
  overflow: auto;
}

.modal__content.overflow-auto {
  overflow: auto;
}
