.draft-title {
  font-size: 1.5rem;
}

.draft-title .editable-text__content {
  max-width: 70%;
  min-width: 60%;
}

.draft-title .input input {
  font-size: 1.5rem;
  color: var(--font-dark);
  padding: 0;
  background: transparent;
  border: transparent;
  text-align: left;
}

.draft-title input[disabled] {
  opacity: 1;
  cursor: default;
}
