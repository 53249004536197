.spinner-holder{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  opacity: 1;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.spinner--large {
  margin-top: 20px;
  border: 10px solid transparent;
  border-radius: 50%;
  border-top: 10px solid var(--dark-bg);
  border-bottom: 10px solid var(--dark-bg);
  width: 80px;
  height: 80px;
}

.spinner--medium {
  margin-top: 10px;
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid var(--dark-bg);
  border-bottom: 5px solid var(--dark-bg);
  width: 40px;
  height: 40px;
}

.spinner--small {
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid var(--dark-bg);
  border-bottom: 3px solid var(--dark-bg);
  width: 20px;
  height: 20px;
}

.spinner__message {
  font-size: 1.2rem;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
