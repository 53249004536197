.teams .header {
  justify-content: space-between;
}

.teams .header .button.active {
  color: var(--font-dark);
  background-color: #fff;
}

.teams .section__content {
  flex-direction: column;
  width: 50%;
  transition: none;
}

.teams__list {
  width: 100%;
  background-color: #fff;
}

.container--splitted .teams .section__content {
  width: 100%;
}

.teams__title {
  font-size: 1rem;
  font-weight: 700;
  margin: 20px 0;
}

.teams__title--large {
  font-size: 1.3rem;
  margin: 20px 0 30px 0;
}
