.conversation-tab {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 33px;
  position: relative;
  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid var(--dark-bg);
  transition: none;
}

.conversation-tab--open {
  width: 320px;
  height: 350px;
  top: -317px;
}

.conversation-tab__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.conversation-tab__info:hover {
  background-color: var(--lighter-bg);
}

.conversation-tab__close {
  padding: 0 10px;
}

.conversation-tab__close .icon {
  width: 20px;
}

.conversation-tab__title {
  display: flex;
  overflow: hidden;
  flex: 1;
  padding: 10px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.conversation-tab__content {
  display: none;
  max-height: calc(100% - 42px);
  flex: 1;
  padding: 5px;
}

.conversation-tab--open .conversation-tab__content {
  display: block;
}

.conversation-tab__content .chat__date-separator {
  padding: 2px 0;
}

.conversation-tab__content .chat__input-editor {
  padding: 10px;
}

.conversation-tab__content .chat__msg-header .chat__msg-time {
  text-align: right;
}

.conversation-tab__content .chat__msg {
  margin: 10px 0;
}

.conversation-tab__content .chat__msg-content {
  margin-top: 5px;
  padding: 5px;
}

.conversation-tab__content .chat__msg-single .chat__msg-time {
  font-size: 0.9em;
  height: 6px;
  margin-bottom: 3px;
}
