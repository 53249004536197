.tickets__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.tickets__filter .input {
  width: 400px;
}

.tickets--error {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tickets--error .tickets__error-msg {
  margin: 0 0 20px 0;
}
