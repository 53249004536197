.dialog__content .process-preview {
  max-height: 400px;
  overflow: auto;
  margin-bottom: 20px;
}

.process-preview {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.4;
}

.process-preview > h2 {
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  margin: 10px 0;
}

.process-preview h3 {
  font-weight: 700;
}

.process-preview__slots, .process-preview__steps {
  flex: 1;
}
