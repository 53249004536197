.avatar {
  display: flex;
  align-items: center;
}

.avatar--small .avatar__image {
  width: 30px;
  height: 30px;
}

.avatar--medium .avatar__image {
  width: 50px;
  height: 50px;
}

.avatar--big .avatar__image {
  width: 150px;
  height: 150px;
}

.avatar__image {
  position: relative;
  display: block;
}

.avatar__image img {
  position: relative;
}

.avatar__status {
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
  height: 35%;
  z-index: 1;
  display: block;
  background-color: transparent;
  border-radius: 50%;
}

.avatar__status:before {
  content: "";
  display: block;
  width: 80%;
  height: 80%;
  position: relative;
  left: 10%;
  top: 10%;
  background-color: transparent;
  border-radius: 50%;
}

.avatar__status--online {
  background-color: #fff;
}

.avatar__status--online:before {
  background-color: var(--status-online);
}

.avatar__status--unread {
  background-color: #fff;
}

.avatar__status--unread:before {
  background-color: var(--status-unread);
}

.avatar__image img {
  border-radius: 50%;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}

.avatar__name {
  padding-left: 8px;
  color: var(--font-dark);
}
