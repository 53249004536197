.user-teams-and-roles {
  width: 100%;
  background-color: #fff;
}

.user-teams-and-roles__team {
  display: flex;
  align-items: center;
  padding: 10px;
  min-height: 30px;
}

.user-teams-and-roles__name {
  flex: 1;
}

.user-teams-and-roles__role .react-select {
  margin: 0;
  width: 250px;
}

.user-teams-and-roles__role .react-select > div {
  border: 1px solid var(--lighter-bg);
}
