.error-message {
  font-size: 1rem;
  line-height: 1.4;
  max-width: 600px;
  margin: 50px auto;
}

.error-message h1 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.error-message p {
  margin-bottom: 20px;
}

.error-message button {
  margin-right: 10px;
}
