.begin-process .process-selector__title {
  font-weight: 700;
  margin-bottom: 5px;
}

.begin-process .process-selector .react-select__menu-list {
  max-height: 150px;
}

.begin-process p {
  margin-top: 10px;
}

.begin-process ul {
  margin-bottom: 10px;
  padding-left: 20px;
}

.begin-process li {
  list-style: disc;
}

.begin-process-dialog {
  min-height: 300px;
}
