.reference-targets {
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: #fff;
}

.reference-targets .hidden {
  display: none;
}

.tabs {
  display: flex;
  text-align: center;
}

.tabs__tab {
  flex: 1;
  padding: 10px;
}

.tabs__tab:hover {
  cursor: pointer;
}

.tabs__tab:first-child {
  border-top-left-radius: 3px;
}

.tabs__tab:last-child {
  border-top-right-radius: 3px;
}

.tabs__tab:not(.selected) {
  color: var(--font-light);
  background-color: var(--dark-bg);
}
