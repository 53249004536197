.chat {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat__messages {
  flex: 1;
  overflow: auto;
}

.chat__msg {
  margin: 20px 0;
}

.chat__msg--info {
  padding: 20px 0;
  text-align: center;
}

.chat__msg-header {
  position: relative;
  display: flex;
  align-items: center;
}

.chat__msg-header .chat__msg-time {
  position: absolute;
  width: 100%;
  text-align: center;
}

.chat__msg-user {
  font-weight: 700;
  margin-right: 20px;
}

.chat__msg-content {
  margin-top: 10px;
  padding: 10px;
  border-radius: 17px;
  background-color: #fff;
}

.chat__msg-single {
  position: relative;
  padding: 5px;
  margin: -5px 0;
  border-radius: 3px;
}

.chat__msg-single--selected {
  background-color: antiquewhite;
}

.chat__msg-single:first-child .chat__msg-time {
  display: none;
}

.chat__msg-single .chat__msg-time {
  display: block;
  height: 10px;
  margin-bottom: 5px;
  opacity: 0;
  text-align: center;
}

.chat__msg-single:hover .chat__msg-time, .chat__msg-single--selected .chat__msg-time {
  opacity: 1;
}

.chat__msg-content .message {
  line-height: 1.4;
}

.chat em {
  font-style: italic;
}

.chat__date-separator {
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 10px 0;
  text-align: center;
  font-weight: 700;
  background-color: var(--lighter-bg);
}

.chat__date-separator::before {
  position: absolute;
  top: 50%;
  display: block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--light-bg);
}

.chat__date-separator .date {
  position: relative;
  z-index: 1;
  text-transform: capitalize;
  padding: 0 15px;
  background-color: var(--lighter-bg);
}

.chat__msg--user-joined {
  display: flex;
}

.chat__msg--user-joined a {
  margin: 0 5px;
  color: var(--font-dark);
}


.chat__msg--state-changed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat__msg--state-changed a {
  color: var(--font-dark);
}

.chat__msg--state-changed .chat__msg-time {
  padding-bottom: 10px;
}
