.process-step {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: var(--lighter-bg);
}

.process-step .process-slot {
  box-shadow: 0px 2px 7px rgba(0,0,0,0.2);
}

.process-step .button .icon {
  margin-right: 6px;
}

.process-step__slots {
  margin-bottom: 20px;
}

.process-step > .process-form__title .process-form__title-controls {
  opacity: 0;
}

.process-step:hover > .process-form__title .process-form__title-controls {
  opacity: 1;
}
