.draft-files__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 46px;
  background-color: var(--light-bg);
}

.draft-files__title h3 {
  font-size: 18px;
}

.draft-files__list {
  background-color: #fff;
}

.draft-files__file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.draft-files__preview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: 20px;
  color: var(--font-dark);
  cursor: pointer;
}

.draft-files__preview:hover {
  color: var(--font-light);
  background-color: var(--dark-bg);
}

.draft-files__thumb {
  min-width: auto;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.draft-files__file-info {
  display: flex;
  flex-direction: column;
}

.draft-files__file-usage {
  margin-top: 5px;
  font-size: 14px;
}

.draft-files__controls {
  padding: 20px;
}

.draft-files__controls .button {
  margin-left: 20px;
}

.draft-files__controls .button:first-child {
  margin-left: 0;
}
