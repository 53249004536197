.bookpart__item--module .process-preview {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  color: var(--font-dark);
}

.bookpart__item--module .bookpart__process {
  background: none;
  color: var(--font-dark);
}

.bookpart__item--module .begin-process-dialog .dialog__content {
  overflow: visible;
}

.bookpart__title .highlight {
  color: #fff;
}

.bookpart_id-input input {
  border-style: solid;
  border-color: black;
}

.bookpart_id-controls {
  display: inline;
}

.bookpart_id-controls .icon {
  cursor: pointer;
}
