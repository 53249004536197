.update-slots {
  margin: 10px 0;
}

.update-slots > ul {
  margin: 10px 0;
}

.update-slots > ul li {
  line-height: 1.4;
  list-style: disc;
  margin-left: 20px;
}

.update-slots__slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.update-slots__slot .button:last-child {
  margin-left: 10px;
}

.update-slots__info {
  display: flex;
  align-items: center;
  flex: 1;
}

.update-slots__info .update-slots__name {
  margin-right: 10px;
}

.update-slots__info .avatar {
  margin-right: 10px;
}
