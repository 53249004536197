.usersList {
  width: 100%;
  color: var(--font-dark);
}

.usersList__filter {
  margin-bottom: -10px;
}

.usersList__team {
  margin-bottom: 20px;
  background-color: #fff;
}

.usersList__team-name {
  display: flex;
  padding: 10px;
}

.usersList__list {
  max-height: 300px;
  overflow: auto;
  background-color: #fff;
}

.usersList__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-bg);
  cursor: pointer;
}

.usersList__item:hover {
  color: var(--font-light);
  background-color: var(--dark-bg);
}

.usersList__item--no-results {
  padding: 10px;
}

.usersList__info {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.usersList__avatar {
  margin-right: 10px;
}
