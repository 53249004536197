.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  min-height: 46px;
  border-radius: 11px;
  margin: 0 15px 46px 0;
  padding: 5px 0;
  background-color: var(--light-bg);
}

.card.card--editing {
  border-radius: 0;
}

.card__content {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.card__buttons {
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.card__title {
  max-width: 155px;
  margin-left: 20px;
  margin-right: 20px;
  color: var(--font-dark);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
}

.card.card--editing .card__title {
  margin-right: unset;
}

.card__book-name {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__book-team {
  font-size: 14px;
  margin: 3px 0;
}

.card .limitedui {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

.card .limitedui .button {
  margin-left: 6px;
}

.bookList__details-icon {
  margin-right: 10px;
}
