.mentions__list {
  background: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mentions__item {
  align-items: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  display: flex;
  height: 32px;
  padding: 4px 8px;
  cursor: pointer;
}

.mentions__item:hover {
  background-color: var(--lighter-bg);
}

.mentions__item.selected {
  background-color: var(--dark-bg);
}

.mentions__item.selected .avatar__name {
  color: #fff;
}

.mentions__item:last-of-type {
  border-bottom: 1px solid #ddd;
}