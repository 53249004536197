.sidebar {
  display: flex;
  flex-direction: column;
  width: 250px;
  border-right: 1px solid #fff;
}

.sidebar__toggle.button {
  display: inline-flex;
  background: none;
  color: var(--font-dark);
}

.sidebar .team-switcher {
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0;
  margin: 10px 20px 0px 20px;
  width: calc(100% - 40px);
}

.sidebar.small {
  width: 70px;
}

.sidebar.small .menu-toggler .button {
  font-size: 0;
  margin-left: 8px;
}

.sidebar.small .team-switcher .select-list {
  position: absolute;
  max-width: 250px;
  left: 20px;
  top: 116px;
}

.sidebar.small .team-switcher__box {
  width: 36px;
  justify-content: center;
}

.sidebar.sidebar.small .team-switcher__value {
  display: none;
}

.sidebar.small .nav__text {
  font-size: 0;
}

.sidebar.small .nav__link {
  margin: 0;
}

.sidebar.small .nav__link a {
  padding: 0;
  justify-content: center;
}

.sidebar.small .nav__link a .icon {
  margin-right: 0;
}

.sidebar.small .notifications__counter {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 13px;
  height: 13px;
  font-size: 0;
}

.menu-toggler .button {
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0;
  margin: 25px 0 0 30px;
  font-size: 18px;
  background-color: transparent;
}

.menu-toggler .button .icon {
  fill: var(--dark-bg);
  padding-right: 20px;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 10px;
}

.nav ul {
  width: 100%;
}

.nav__link {
  position: relative;
  margin-left: 15px;
}

.nav__link:hover .nav__hoverbox {
  display: flex;
}

.nav__hoverbox {
  display: none;
  flex-direction: column;
  width: 400px;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 99;
  border: 1px solid var(--light-bg);
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  background-color: var(--light-bg);
}

.nav__link .nav__hoverbox a {
  padding: 0;
}

.nav__hoverbox a.nav__link {
  padding: 15px 20px;
  box-sizing: border-box;
}

.nav__hoverbox a.notification__link {
  margin-left: 15px;
  height: auto;
  line-height: 1.2;
  padding: 5px 0;
}

.nav__hoverbox .notification {
  padding: 0 15px;
}

.nav__hoverbox .notification__link:hover .notification__content {
  color: #fff;
}

.nav__hoverbox a.show-more {
  padding: 10px;
  justify-content: center;
}

.nav__link * {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.nav__link a {
  text-decoration: none;
  color: var(--font-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 46px;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.nav__link a:hover, .nav__link a.active {
  color: var(--font-light);
  background-color: var(--dark-bg);
}

.nav__link a:hover .icon, .nav__link a.active .icon {
  fill: white;
}

.nav__link a .icon {
  margin-right: 20px;
}

.nav__content {
  display: flex;
  align-items: center;
}

nav .notifications__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  font-size: 14px;
  color: var(--font-light);
  border-radius: 50%;
  background-color: var(--danger);
}

nav .notifications__counter > span {
  margin-top: -2px;
}

.dialog__content.nav__ticket-creator-dialog {
  color: inherit;
}
