.modules {
  display: flex;
  flex-direction: column;
}

.modules__filters {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  max-width: 800px;
}

.modules__filters div {
  max-width: 300px;
}

.modules--error {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modules--error .modules__error-msg {
  margin: 0 0 20px 0;
}
