.tooltip__wrapper {
  position: absolute;
  z-index: 9999999;
  font-size: 1rem;
  transition: none;
}

.tooltip__content {
  position: relative;
  color: #fff;
  background: var(--dark-bg);
  border-radius: 3px;
  padding: 5px;
}

/*
  Arrow down
*/
.tooltip__arrow--down {
  position: absolute;
  bottom: -8px;
  left: calc(50% - 8px);

  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--dark-bg);
}

/*
  Arrow up
*/
.tooltip__arrow--up {
  position: absolute;
  top: -8px;
  left: calc(50% - 8px);

  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--dark-bg);
}

/*
  Arrow right
*/
.tooltip__arrow--right {
  position: absolute;
  top: calc(50% - 8px);
  right: -8px;

  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--dark-bg);
}

/*
  Arrow left
*/
.tooltip__arrow--left {
  position: absolute;
  top: calc(50% - 8px);
  left: -8px;

  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid var(--dark-bg);
}
