input {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  color: var(--font-dark);
  text-align: center;
  padding: 10px;
  outline: none;
  background-color: #fff;
  border-radius: 17px;
  box-sizing: border-box;
  border: none;
}

input[type="submit"] {
  color: var(--font-light);
  margin-bottom: 0;
  cursor: pointer;
  background-color: var(--dark-bg);
}

input[type="submit"]:hover {
  background-color: #303d50;
}

input[type="disabled"] {
  opacity: 0.6;
  cursor: not-allowed;
}

.input input {
  display: block;
  width: 100%;
}

.input input[type="checkbox"] {
  width: auto;
}

.input.valid input {
  color: var(--green);
  border: 1px solid var(--green);
}

.input.invalid input {
  color: var(--red);
  border: 1px solid var(--red);
}

.input__error {
  position: relative;
  display: inline-block;
  font-size: 0.8rem;
  color: var(--red);
}
