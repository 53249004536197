.teamList {
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
}

.teamList__item {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.teamList__item:hover .teamList__username {
  font-weight: 700;
}

.teamList__username {
  color: var(--font-dark);
  margin-left: 20px;
}
