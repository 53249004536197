.toolbox-list__nesting {
    display: flex;
}

.toolbox-list__nesting > * {
  flex: 1;
}

.toolbox-list__style .react-select__option {
  position: relative;
}

.toolbox-list__bullet-example {
  position: absolute;
  display: inline-block;
  top: 8px;
  right: 16px;
  bottom: 8px;
  vertical-align: middle;
}

.toolbox-list__numbering-example {
  text-align: right;
  font-size: 75%;
  color: #999;
}
