.team__settings {
    margin-top: 20px;

    display: grid;
    align-items: baseline;
    grid-template-columns: min-content 1fr;
    column-gap: 8px;
}

.team__settings--label {
    white-space: nowrap;
}
