.is-dragging {
  cursor: grabbing;
}

.book__search {
  position: absolute;
  right: 20px;
}

.book .process-selector {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.book .process-selector__title {
  margin-right: 20px;
}

.book .process-selector .react-select {
  max-width: 300px;
  margin-bottom: 0;
}

.book-collection {
  width: 100%;
}

.book-collection .nestable-list {
  position: relative;
  margin-top: 0;
  background-color: #fff;
}

.book-collection .nestable-list .nestable-list {
  margin-top: 0;
}

.book-collection.nestable .nestable-item-0 > .nestable-list {
  padding-left: 0;
  margin-left: 20px;
}

.book-collection .nestable-item {
  margin: 0;
}

.nestable-item-name {
  padding: 15px;
}

.nestable-item-name .limitedui {
  display: none;
}

.nestable-item-name .bookpart__item--module .limitedui:last-child {
  display: flex;
}

.nestable-item-name:hover .limitedui {
  display: flex;
}

.nestable-item-0.nestable-has-children {
  background-color: #fff;
}

.nestable-item-0.nestable-has-children > .nestable-list .nestable-item-name {
  padding-left: 40px;
}

.nestable-item-0 > .nestable-list {
  position: relative;
  margin-left: 20px;
}

.nestable-has-children .nestable-list .nestable-item-name {
  padding-left: 60px;
}

.nestable-has-children .nestable-item-name:hover .bookpart__title {
  font-weight: 700;
  outline: none;
}

.nestable-has-children .nestable-item-name:hover .bookpart__title .input input {
  font-weight: 700;
}

.nestable-collapsed {
  display: none;
}

.bookpart__item {
  display: flex;
  align-items: center;
  height: 20px;
}

.bookpart__item button {
  margin-right: 10px;
}

.bookpart__title {
  font-size: 16px;
  display: inline-flex;
  flex: 1;
  height: 30px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.bookpart__item--module .bookpart__title {
  cursor: pointer;
}

.bookpart__title .input {
  margin-bottom: 0;
}

.bookpart__title .input input {
  font-size: 16px;
  color: var(--font-dark);
  padding: 10px 0;
  background: transparent;
  border: 0;
}

.bookpart__info {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.bookpart__info .avatar {
  margin-right: 10px;
}

.bookpart__step {
  margin-right: 20px;
}

.bookpart__icon {
  cursor: pointer;
}

.bookpart__icon .icon {
  padding-right: 20px;
}

.bookpart__info .stacked {
  width: 170px;
}

.book__controls {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}

.book__controls .button {
  margin-left: 10px;
}

.book-module-details-wrapper{
  display: grid;
}
