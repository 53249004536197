.equation {
    position: relative;
}

.equation::after {
    content: '(' attr(data-label) ')';
    position: absolute;
    right: 0;
    /* 19.5px is the line-height for this element in current style */
    top: calc((100% - 19.5px) / 2);
}
