.outline-wrapper, .outline-wrapper * {
  box-sizing: border-box;
  /* transition: none; */
  line-height: 1.5;
}

.outline-wrapper {
  padding: 30px 10px 30px 30px;
  width: 357px;
  background-color: white;
  position: sticky;
  top: 46px;
  left: 0;
  height: calc(100vh - 46px);
  overflow: auto;
  border-right: 1px solid var(--dark-bg);
}

.outline-wrapper--collapsed {
  padding: 30px 10px 30px 15px;
  width: 30px;
  cursor: pointer;
}

.outline-wrapper--collapsed > ol {
  display: none;
}

.outline-wrapper .button {
  padding: 7px 10px;
}

.outline-wrapper > ol {
  box-shadow: inset 3px 0 1px -2px var(--lighter-bg);
  overflow: hidden;
}

.outline-element li {
  box-shadow: inset 3px 0 0 -2px var(--lighter-bg);
  display: block;
  margin-left: 10px;
}

.admonition.outline-element li {
  margin-left: 11px;
}

.outline-element li:last-of-type {
  box-shadow: none;
}

.outline-element .section__title {
  height: 24px;
}

.outline-wrapper--label {
  transform: translateX(-13px);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-top: 10px;
  font-weight: bold;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none
}

.outline-wrapper--label-no-display {
  display:none
}

.outline-element {
  line-height: 1.5;
}

.outline-wrapper > ol {
  padding-top: 10px;
}

.outline-wrapper li {
cursor: pointer;
}

.outline-element-with-invisible-list ol {
  display: none;
}

.outline-element.section {
  display: grid;
  grid-template-columns: 40px 1fr;
  /* margin-top: 5px; */
}

.outline-element.figure {
  display: grid;
  grid-template-columns: 20px 1fr;
}

.outline-element.exercise {
  display: grid;
  grid-template-columns: auto 1fr;
}

.outline-element.admonition,
.outline-element.rule {
display: grid;
grid-template-columns: 40px 20px 50px auto;
}

.outline-element .admonition {
  transform: translateX(-2px);
}

.outline-element.admonition > ol,
.outline-element.figure > ol,
.outline-element.section > ol {
grid-row: 2 / 3;
grid-column: 1 / -1;
}

.outline-element.section > ol {
  margin-left: 1px;
}

.outline-element.section .section__title {
  font-weight: bold;
}

.outline-element--icon,
.outline-wrapper--toggler,
.outline-element--toggler {
display: flex;
}

.outline-element--icon {
margin-right: 10px;
}

.outline-wrapper--toggler {
width: max-content;
}

.outline-wrapper--toggler,
.outline-element--toggler {
cursor: pointer;
transform: translateX(-50%);
}

.outline-element--label,
.outline-element.section__title,
.outline-element.admonition__title,
.outline-element.text {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 24px;
}

.outline-element--label {
  transform: translateY(-2px);
}

.outline-element {
line-height: 1.2;
}

.outline-element.admonition .admonition__title,
.outline-element.rule .rule__kind {
margin-left: 10px;
}

.OutlineLineContainer {
  height: 24px;
  width: 30px;
}

.section .OutlineLineContainer,
.lineSection-title .OutlineLineContainer{
  width: 20px;
}

.OutlineLineContainer--upper {
  height: 50%;
  border-bottom: 1px solid transparent;
  border-color: var(--lighter-bg);
}

.OutlineLineContainer--lower {
  height: 50%;
}

.outline-element li:last-child .OutlineLineContainer--upper{
  height: 50%;
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  border-color: var(--lighter-bg);
}

.outline-wrapper .lineSection {
  display: grid;
  grid-template-columns: 37px auto;
}

.section .lineSection {
  display: grid;
  grid-template-columns: 30px auto;
}

.outline-wrapper .lineSection-title {
  display: grid;
  grid-template-columns: 35px auto;
}

.outline-wrapper .lineSection-admonition {
  display: grid;
  grid-template-columns: 33px auto;
}

.outline-element .lineSection-admonition {
  display: grid;
  grid-template-columns: 29px auto;
}

.outline-wrapper .admonition .lineSection {
  display: grid;
  grid-template-columns: 30px auto;
}

.admonition .OutlineLineContainer {
  height: 24px;
  width: 20px;
}

.outline-element .admonition__title::before {
  content: ": ";
}

.outline-wrapper .section .lineSection-title {
  display: grid;
  grid-template-columns: 28px auto;
}

.outline-wrapper--toggler {
  background: transparent;
  border: 1px solid transparent;
}

.outline-wrapper--toggler .icon svg {
  fill: var(--font-dark);
}

.expandable-element--toggler .icon svg {
  fill: var(--font-dark);
  transform: translateY(-5px);
}

.icon--extra-small svg {
  transform: translateY(-2px);
}

.expandable-element--toggler {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  min-width: 0;
  width: 15px;
  height: 20px;
}

.outline-wrapper--toggler,
.outline-element--toggler,
.outline-wrapper li {
  cursor: pointer;
}

.outline-element.text,
.outline-element--label,
.outline-element .section__title,
.admonition li,
.admonition__title {
  transition: none;
}

.outline-element.text:hover,
.outline-element--label:hover,
.outline-element .section__title:hover,
.admonition li:hover,
.admonition__title:hover {
  background-color: var(--lighter-bg);
  transition: none;
}

.outline-element.text:active,
.outline-element--label:active,
.outline-element .section__title:active,
.admonition li:active,
.admonition__title:active {
  background-color: var(--dark-bg);
  color: white;
  transition: none;
}

.expandable-element--toggler:hover {
  background-color: var(--lighter-bg);
}
