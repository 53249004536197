.processes {
  flex-direction: column;
}

.processes__create-new {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  max-width: 800px;
}

.process__dialog-content {
  line-height: 1.4;
  margin-bottom: 20px;
}

.process__dialog-content p {
  margin-bottom: 10px;
}

.processes--error {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.processes--error .processes__error-msg {
  margin: 0 0 20px 0;
}
