.notification {
  width: 100%;
  color: var(--font-dark);
}

.notification__content {
  display: flex;
  align-items: flex-start;
}

.notification__action {
  margin-bottom: 5px;
}
