/* .suggestion__controls > button:first-child {
  margin-bottom: 10px;
  margin-right: 5px;
} */

.suggestion__controls > button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.button.suggestion-box__control {
  margin-right: 0px;
}

.suggestion-tools__box_area {
  margin-top: 10px;
  min-height: 150px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--dark-bg);
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.suggestion__controls-acceptAll {
  background-color: #56a860;
}

.suggestion__controls-rejectAll {
  background-color: #dd6760;
}

.suggestion-tools__checkbox-with-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.suggestion-tools__checkbox-with-label .suggestion-tools__label {
  padding-right: 5px;
}

.suggestion-tools__checkbox-with-label .checkbox__checkmark {
  position: inherit;
}

.suggestionBox__modal--removeAll {
  display: flex;
  flex-direction: column;
  width: 250px;
  align-items: center;
}

.suggestionBox__modal--removeAll_text {
  padding: 15px;
}

.suggestionBox__modal--removeAll-buttons {
  display: flex;
  flex-direction: row;
}

.suggestionBox__modal--removeAll_button {
  margin: 10px;
}
