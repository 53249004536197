.draft-info__permission:first-child {
  padding-left: 5px;
}

.draft-info__permission::after {
  content: ', ';
}

.draft-info__permission:last-child::after {
  content: none;
}

.draft-info__content {
  display: flex;
  flex-direction: column;
}

.draft-info__content span {
  margin-bottom: 5px;
}
