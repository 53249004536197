.toolgroup {
  margin-top: 12px;
}

.toolgroup--header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.toolgroup--header > .icon {
  margin: 4px;
}

.toolgroup--content {
  display: none;
}

.toolgroup.open > .toolgroup--content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
