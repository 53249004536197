.mention {
  padding: 2px;
  color: var(--font-dark);
  border-radius: 2px;
  background-color: var(--lighter-bg);
}

.mention--focused {
  background-color: var(--light-bg);
}
