.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  fill: var(--dark-bg);
}

.button .icon {
  fill: white;
}

.icon svg {
  max-width: 60px;
}

.icon--extra-small svg {
  width: 15px;
  height: 15px;
}

.icon--small svg {
  width: 20px;
  height: 20px;
}

.icon--medium svg {
  width: 25px;
  height: 25px;
}

.icon--big svg {
  width: 40px;
  height: 40px;
}
