.linkbox {
  min-width: 360px;
  padding: 10px;
  background-color: var(--lighter-bg);
}

.linkbox__text {
  margin-bottom: 10px;
}

.linkbox__label-text {
  margin-bottom: 4px;
  display: inline-block;
  font-weight: 700;
}

.linkbox__target {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.linkbox__target-item {
  margin: 10px 0;
  color: #fff;
}

.linkbox__buttons .button {
  margin-right: 10px;
}
