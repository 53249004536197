.page-navigation__wrapper {
  width: 100%;
}

.page-navigation {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.page-navigation__button {
	background: top;
	padding: 0;
  margin: 0;
  min-width: auto;
}

.page-navigation__button .icon {
  fill: var(--font-dark);
}

.page-navigation__button--prev {
  margin-right: 20px;
}

.page-navigation__button--next {
  margin-left: 20px;
}
