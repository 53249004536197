html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}

:root {
  --light-bg: #b3b3b3;
  --lighter-bg: #e6e6e6;
  --dark-bg: #293b55;
  --darkest-bg: #232d3b;

  --font-dark: #293b55;
  --font-light: white;

  --main-decoration: #ad35bb;
  --secondary-decoration: #0080db;

  --status-online: #27d768;
  --status-unread: #db0037;
  --status-ready: #0080db;
  --status-translation: #ff9a3e;
  --status-review: #db0037;
  --status-done: #27d768;

  --danger: #ee714f;

  --red: #db0037;
  --dark-red: #c20635;
  --green: #27d768;
  --dark-green: #1ca04d;
  --orange: #ee714f;
  --dark-orange: #e45d38;
  --blue: #0080db;
  --dark-blue: #005a9b;

  --header-height: 46px;
  --header-margin-top: 25px;
}

body {
  position: fixed;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-size: 10px;
  color: var(--font-dark);
  background-color: var(--lighter-bg);
}

body * {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

html, body, #root, .container {
  height: 100%;
}

.frame {
  border: 1px solid var(--lighter-bg);
  border-bottom: none;
}

a {
  text-decoration: none;
}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

[draggable="true"] {
  cursor: grab;
}

.container {
  font-size: 1rem;
}

.container--main {
  display: flex;
}

.container--splitted {
  /* display: flex; */
  display: grid;
  grid-template-columns: 2fr 1fr;
}

main {
  flex: 1;
  max-width: 100%;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.list .list {
  margin-top: 10px;
  padding-left: 20px;
}

.list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid var(--light-bg);
}

.list__title {
  flex: 1;
}

.list__buttons button {
  margin-left: 5px;
}

.error {
  display: block;
  color: var(--red);
  margin-bottom: 20px;
}

.success {
  display: block;
  color: var(--green);
  margin-bottom: 20px;
}

input[type="submit"] {
  display: inline-flex;
  border: none;
  font: inherit;
  font-size: 16px;
  line-height: normal;
  padding: 7px 20px;
  color: var(--font-light);
  background-color: var(--dark-bg);
  min-width: 75px;
  cursor: pointer;
  border-radius: 17px;
}

input[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

textarea {
  display: block;
  width: 100%;
  min-height: 100px;
  padding: 15px;
  font-size: 0.9rem;
  background-color: #fff;
  border: 1px solid var(--dark-bg);
  border-radius: 4px;
  box-sizing: border-box;
}

form .input {
  margin-bottom: 10px;
}

form input[type="submit"] {
  margin-right: 10px;
}

.cnxml title {
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8;
}

.cnxml metadata {
  display: none;
}

.cnxml para {
  margin: 5px 0;
  display: block;
}

.cnxml list {
  display: block;
  padding-left: 20px;
}

.cnxml item {
  display: list-item;
}

.cnxml emphasis {
  font-weight: 700;
}

.cnxml emphasis[effect="italics"] {
  font-style: italic;
}

.cnxml footnote {
  display: block;
}

.cnxml footnote::before {
  counter-increment: footnote;
  content: counter(footnote) ". ";
}

.cnxml note, .cnxml example {
  background-color: var(--light-bg);
  display: block;
  padding: 10px;
  margin-bottom: 10px;
}

.cnxml row {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--dark-bg);
}

.cnxml entry {
  display: flex;
  flex: 1;
  padding: 5px;
  border-right: 1px solid var(--dark-bg);
}

.cnxml row entry:last-child {
  border: none;
}

.cnxml newline {
  display: block;
}

.cnxml section {
  margin: 10px 0;
}

.cnxml exercise {
  display: block;
  border-inline-start: 4px solid var(--dark-bg);
  margin-inline-start: 10px;
  margin-bottom: 10px;
  padding-inline-start: 8px;
}

.cnxml exercise::before {
  display: block;
  color: var(--dark-bg);
}

.cnxml exercise * > :first-child {
  margin-top: 0;
}

.cnxml problem, solution, commentary {
  display: block;
  margin: 5px 0;
}

.cnxml exercise problem::before {
  content: 'Problem ' counter(exercise) ':';
  font-weight: 700;
}

.cnxml exercise solution::before {
  content: 'Solution ' counter(exercise_solution) ':';
  font-weight: 700;
}

.cnxml exercise commentary::before {
  content: 'Commentary: ';
  font-weight: 700;
}

.react-tooltip-lite {
  font-size: 0.9rem;
  color: var(--font-light);
  background: var(--darkest-bg);
  border-radius: 3px;
}

.react-tooltip-lite-arrow {
  border-color: var(--darkest-bg);
}

.react-select > div {
  border-radius: 17px;
  border: none;
  color: var(--font-dark);
  overflow: hidden;
}

.react-select > div > div {
  justify-content: center;
  padding: 0;
}

.react-select > div > div > div:first-child {
  overflow: visible;
}
