.processes__name-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.processes__team {
  font-size: 14px;
  margin: 3px 0;
}

form.processes__name {
  display: flex;
  flex: 1;
  margin-right: 10px;
}

.process__small-icon {
  cursor: pointer;
  margin-left: 10px;
  color: #b4afaf;
}

.process__small-icon:hover {
  color: var(--font-dark);
}

.process__content-editable {
  outline: none;
  flex: 1;
}

.process__content-editable[contenteditable="true"]:active,
.process__content-editable[contenteditable="true"]:focus {
  border-bottom: 1px solid var(--light-bg);
  padding: 0 0 3px 0;
}

form.processes__name .process__controls {
  display: flex;
  flex: 1;
  max-width: 60px;
}

.processes__controls {
  display: flex;
  align-items: center;
}

.processes__controls .button:not(.button--danger) {
  background: none;
  color: #b4afaf;
}

.processes__controls .button:hover {
  color: var(--font-dark);
}
