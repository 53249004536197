.toolbox {
  position: sticky;
  top: 46px;
  right: 0;
  height: calc(100vh - 16px - 46px); /* 100vh - padding-bottom and top value - top */
  width: 260px;
  padding: 8px;
  border-left: 1px solid var(--dark-bg);
  background-color: #fff;
  overflow: auto;
}

.toolbox-tools {
  padding-top: 60px;
}

.toolbox--collapsed {
  width: 15px;
  cursor: pointer;
}
.toobox-tools--collapsed {
  display: none;
}

.toolbox-label {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-top: 65px;
  font-weight: bold;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none
}

.toolbox-label--invisible {
  display: none;
}

.toolbox-label[aria-expanded="true"] {
  display: none;
}

.toolbox__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbox__label {
  width: 100%;
}

.toolbox__title {
  display: block;
  margin-bottom: 3px;
}

.toolbox__select {
  width: 100%;
  margin: 10px 0;
  font-size: 0.9rem;
}

.toolbox-toggler {
  float: right;
  width: max-content;
  cursor: pointer;
  padding-top: 30px;
  background: transparent;
  transform:translateX(40%);
}
.toolbox-toggler .icon svg {
  fill: var(--font-dark);
}

.toolbox .button .icon svg {
  fill: var(--font-dark);
}

.toolgroup.open > .toolbox__insert-buttons {
  justify-content: flex-start;
}

.toolbox__button--insert {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  padding: 5px;
  margin-right: 2px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: var(--font-dark);
  background: transparent;
}

.toolbox__button--insert:hover {
  border-color: var(--dark-bg);
}

.toolbox__button--with-tooltip {
  display: inline-block;
}

.toolbox__button--only-icon {
  padding: 5px;
  margin-right: 5px;
  color: var(--font-dark);
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  min-width: 0;
}

.toolbox__button--only-icon.active {
  color: var(--font-light);
  background-color: var(--dark-bg);
}

.toolbox__button--only-icon.active .icon svg {
  fill: var(--font-light);
}

.toolbox__button--only-icon:hover {
  border-color: var(--dark-bg);
}

.toolbox .input input, .toolbox .react-select > div {
  background-color: var(--lighter-bg);
}

/* .suggestion_text {
  margin-right: 5px;
  padding: 2px;
  min-height: 100px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid var(--dark-bg);
} */

.suggestion-box__control_arrows_div {
  display: flex;
  justify-content: space-between;
  background-color: #ececec;
  border-radius: 5px;
}
