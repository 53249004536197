.draftsList {
  width: 100%;
  overflow-y: scroll;
}

.draftsList .list .list {
  margin-top: 0;
}

.draftsList .list__item {
  flex-wrap: wrap;
  padding: 0;
  border-bottom: none;
}

.draftsList__book {
  margin-bottom: 20px;
  background-color: white;
}

.draftsList__book-title {
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 46px;
  background-color: var(--light-bg);
}

.draftsList .list__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: 1.2;
}

.draftsList__draft-title {
  width: 100%;
  color: var(--font-dark);
  padding: 15px 0;
  flex: 1;
}

.draftsList__draft-title:hover {
  font-weight: 700;
}

.draftsList .nestable-item-name {
  margin-right: 15px;
}

.draftsList .draft-info {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}

.draftsList__book .nestable-has-children .nestable-list .nestable-item-name {
  padding: 15px 0;
}

.draftsList__details-icon {
  padding: 10px;
}
