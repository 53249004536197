.page-navigation__list {
  display: inline-flex;
  align-items: center;
}

.page-navigation__page {
  min-width: 0;
  background: none;
  color: var(--font-dark);
  padding: 5px 6px;
}

.page-navigation__active-page {
  font-weight: 700;
  cursor: default;
}
