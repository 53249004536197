.definition__label {
  margin-top: 20px;
}

.definition__title {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  margin-bottom: 10px;
}
