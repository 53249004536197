.header {
  max-width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: var(--header-margin-top);
  background-color: var(--dark-bg);
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.header.fixed {
  position: sticky;
  top: 0;
  z-index: 2;
}

.header__title {
  font-size: 18px;
  margin-right: 20px;
  color: var(--font-light);
}

.header .limitedui {
  margin-right: 20px;
}

.header .date {
  margin-right: 20px;
}

.header .button {
  margin-right: 10px;
}

.header .sidebar__toggle {
  margin-right: 0;
}

.header .module__assignee {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.header .module__assignee > span {
  margin-right: 5px;
}

.header .lock-swiping .button {
  margin-left: 10px;
}

.header .close-button {
  position: absolute;
  right: 0;
  margin-right: 0;
  min-width: 0;
}

.header .close-button-module-preview {
  position: fixed;
  right: 0;
  min-width: 0;
  margin-right: 3vw;
  padding: 0px;
  background-color: transparent;
}

.header .close-button .icon svg,
.header .close-button-module-preview .icon svg {
  fill: var(--danger);
}
