.validation-details {
  margin-top: 20px;
  padding: 20px;
  max-height: 250px;
  overflow: auto;
  line-height: 1.4;
  border-radius: 10px;
  background-color: var(--lighter-bg);
  color: var(--font-dark);
}
