.process-preview-step {
  margin-top: 5px;
}

.process-preview-step__slots {
  margin-left: 10px;
  margin-top: 5px;
}

.process-preview-step__links {
  margin-left: 10px;
  margin-top: 5px;
}
