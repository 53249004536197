.resource__card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: var(--light-bg);
  border-radius: 17px;
}

.resource__content {
  display: flex;
  flex-direction: column;
  color: var(--font-dark);
}

.resource__title {
  margin-top: 20px;
  text-align: center;
  display:flex;
  flex-direction: column;
}

.resource__name {
  font-size: 18px;
}

.resource__team {
  font-size: 14px;
  margin: 3px 0;
}

.resource__buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.resource__buttons .button:first-child {
  margin-right: 10px;
}
