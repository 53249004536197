.ReactTags__tag {
  display: inline-block;
  margin: 5px 5px 5px 0px;
  padding: 5px;
  font-size: 0.9rem;
  border-radius: 3px;
  background-color: var(--light-bg);
}

.ReactTags__remove {
  padding: 0 3px;
  cursor: default;
}

.classes {
  width: 100%;
  margin: 10px 0;
}

.classes .ReactTags__tagInput input {
  width: 100%;
  background-color: var(--lighter-bg);
}

.classes__title {
  display: block;
  margin-bottom: 3px;
}
