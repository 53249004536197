.dialog__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}

.dialog__container.supersession {
  z-index: 999;
}

.dialog__content {
  position: relative;
  width: 300px;
  width: 600px;
  max-height: 80%;
  background-color: var(--light-bg);
  border-radius: 17px;
}

.dialog__content--small {
  width: 300px;
}

.dialog__content--medium {
  width: 600px;
}

.dialog__content--big {
  width: 900px;
}

.dialog__title {
  padding: 20px 0;
  font-size: 22px;
  color: #fff;
  line-height: 1.4;
  text-align: center;
}

.dialog__content {
  color: #fff;
}

.dialog__buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dialog__buttons .button, .dialog__buttons input {
  margin: 0;
}

.dialog__buttons--center {
  justify-content: center;
}

.dialog__buttons--start {
  justify-content: flex-start;
}

.dialog__buttons--start .button, .dialog__buttons--start input[type="submit"] {
  margin-right: 10px;
}

.dialog__buttons--end {
  justify-content: flex-end;
}

.dialog__buttons--end .button, .dialog__buttons--end input[type="submit"] {
  margin-left: 10px;
}

.dialog__content .avatar__name {
  color: #fff;
}
