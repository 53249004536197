.color-picker {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.color-picker__item {
  width: 20px;
  height: 20px;
  margin: 5px;
  cursor: pointer;
}

.color-picker__item--active {
  border: 1px solid var(--dark-bg);
  cursor: default;
}

.highlight__content {
  width: 100%;
}

.highlight__content textarea {
  max-width: 250px;
  min-height: auto;
  padding: 5px;
  height: 100px;
  margin-bottom: 10px;
}

.highlight__message {
  display: block;
  color: var(--font-dark);
  background-color: #fff;
  border: 1px solid var(--dark-bg);
  border-radius: 5px;
  overflow: auto;
  height: 70px;
  padding: 5px;
}

.highlight__author {
  margin: 10px 0;
  display: flex;
}

.highlight__author .avatar__image {
  margin-left: 5px;
  width: 20px;
  height: 20px;
}
