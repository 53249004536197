.remote-reference-targets.hide {
  display: none;
}

.remote-reference-targets__controls {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.remote-reference-targets__controls > .button .icon {
  margin-left: -8px;
}

.remote-reference-targets ul li {
  border-bottom: 1px solid var(--light-bg);
}

.remote-reference-targets ul li:last-child {
  border: none;
}

.remote-reference-targets .nestable-item-name {
  padding: 10px;
}

.remote-reference-targets .bookpart__item {
  width: 100%;
  display: flex;
  height: 30px;
}

.remote-reference-targets .bookpart__title {
  white-space: normal;
}

.remote-reference-targets .bookpart__item::before {
  top: 20px;
}

.remote-reference-targets .bookpart__item--module:hover {
  font-weight: 700;
}

.remote-reference-targets .nestable-item-0 .nestable-list::before {
  height: calc(100% - 20px);
}

.remote-reference-targets .bookpart__icon .icon {
  padding-right: 0;
}

.remote-reference-targets .bookpart__icon .icon svg {
  margin-right: 10px;
}
