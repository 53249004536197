.tasks {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
  margin: 8px;

  display: flex;
  flex-direction: column;
}

.task-preview {
  background-color: #fff;
  border-radius: 50vh;
  border: 1px solid var(--dark-bg);
  padding: 5px;
  padding-inline: 10px;
  margin-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  transition: box-shadow 0.25s, transform 0.25s;
}

.task-preview:hover {
  box-shadow: 0px 2px 6px rgba(0,0,0,0.7);
  transform: translateY(-2px);
  cursor: pointer;
}

.active-task {
  min-width: 300px;
}

.active-task__state {
  margin-top: 16px;
}

.active-task__state label {
  display: block;
  text-align: center;
}

.active-task__state progress {
  width: 100%;
}

.active-task__close {
  display: block;
  margin-top: 8px;
  margin-inline: auto;
}

.active-task__close :first-child {
  margin-inline-end: 6px;
}

.task__error {
  margin-top: 16px;
}

.task__error .message-list dt {
  margin-block: 0.5em;
}

.task__error .message-list dd {
  white-space: preserve-breaks;
  margin-left: 2em;
  font-family: monospace;
  font-size: 12px;
}
