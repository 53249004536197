.modules__name-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.modules__team, .modules__books, .modules__draft {
    font-size: 14px;
    margin: 3px 0;
}

.modules__draft a:hover {
    text-decoration: underline;
}

.modules__books a:hover {
    text-decoration: underline;
}

.modules__books a:after {content: ", "}

.modules__books a:last-child:after {content: ""}

.modules__controls {
    display: flex;
    align-items: center;
}

.modules__controls .button:not(.button--danger) {
    background: none;
    color: #b4afaf;
}

.modules__controls .button:hover {
    color: var(--font-dark);
}

.modules__controls .button {
    min-width: 0;
}

.modules__controls .button .icon svg {
    fill: var(--font-dark);
}

.modules__name .input {
    margin-bottom: 0;
}

.modules__name .input input {
    font-size: 1rem;
    color: var(--font-dark);
    padding: 0;
    background: transparent;
    border: transparent;
}
