.invitations {
  width: 350px;
}

.invitations .input, .react-select {
  margin-bottom: 10px;
}

.invitations .permissions {
  margin-bottom: 10px;
}
