.teams__team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--light-bg);
  cursor: pointer;
}

.teams__team:not(.teams__team--selected):hover {
  background-color: var(--light-bg);
}

.teams__team--selected {
  color: var(--font-light);
  background-color: var(--dark-bg);
}

.teams__team--selected .icon svg {
  fill: #fff;
}
