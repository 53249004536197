.step-changer {
  display: flex;
  align-items: center;
}

.step-changer__select-link {
  margin: 0 10px;
  width: 200px;
}

.step-changer__dialog-controls {
  margin-top: 20px;
}

.step-changer__dialog-content {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.step-changer__dialog-content .button {
  margin-bottom: 5px;
}

.step-changer__info {
  margin-bottom: 20px;
}

.step-changer__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--light-bg);
  opacity: 0.9;
}

