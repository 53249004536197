.draft-details__controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.draft-details__buttons .button {
  margin-right: 20px;
}

.draft-details .draft-files {
  margin: 20px 0;
  max-width: 800px;
}

.draft-details .header__title {
  margin-right: 15px;
}

.draft-details .draft-title {
  font-size: 18px;
  color: var(--font-light);
}

.draft-details .draft-title .editable-text__controls .icon {
  fill: var(--lighter-bg);
}

.draft-details .draft-title .editable-text__controls .icon svg {
  height: 20px;
  width: 20px;
}
