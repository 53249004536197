.characters-counter {
  width: 100%;
  margin-bottom: 10px;
}

.characters-counter .toolbox__title {
  margin-bottom: 10px;
}

.characters-counter .toolbox__title span {
  display: block;
  margin-bottom: 10px;
}
