.alternative-ids__title {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-top: 30px;
  cursor: pointer;
  display: inline-block;
}

.alternative-ids__list {
  display: grid;
  grid: 1fr / 1fr 1fr min-content;
}

.alternative-ids__list-col-icon {
  width: 40px;
  display: inline-block;
  cursor: pointer;
}

.alternative-ids__list-elems {
  display: inline-block;
}

.alternative-ids__list-button {
  display: inline-block;
  margin-right: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.alternative-ids__input {
  display: inline-block;
  margin: 5px;
}

.alternative-ids__icon-right {
  transform: translateY(15%);
}
