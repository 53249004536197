.draft-file-preview {
  display: flex;
  flex-direction: column;
}

.draft-file-preview__buttons {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.draft-file-preview__buttons a {
  flex: 1;
  color: var(--font-light);
}

.draft-file-preview__buttons .button {
  margin-left: 20px;
}

.draft-file-preview__element {
  display: flex;
  align-items: center;
  justify-content: center;
}

.draft-file-preview__element img,
.draft-file-preview__element audio,
.draft-file-preview__element video {
  min-width: auto;
  max-width: 100%;
}
