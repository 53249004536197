.change-process-info,
.change-process-validation-error {
  padding: 20px 0;
}

.map-slots__slots {
  display: flex;
  flex-direction: column;
}

.map-slots__slot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-slots__slot .avatar, .map-slots__name, .map-slots__arrow {
  flex: 1;
}

.map-slots__slot .react-select {
  width: 250px;
}
