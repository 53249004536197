.role-manager {
  padding: 10px 20px;
}

.role-manager__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.role-manager__header:hover .role-manager__name {
  font-weight: 700;
}

.role-manager__name .input input {
  text-align: left;
  font-size: 16px;
}

.role-manager__controls .button {
  margin-left: 5px;
}

.role-manager__content {
  display: none;
}

.role-manager__content.active {
  display: block;
}
