.processes__list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 800px;
  background-color: #fff;
}

.processes__item {
  display: flex;
  align-items: center;
  padding: 15px;
}

.processes__item.active {
  font-weight: 700;
}

.processes__controls .button {
  min-width: 0;
}

.processes__controls .button .icon svg {
  fill: var(--font-dark);
}

.processes__name .input {
  margin-bottom: 0;
}

.processes__name .input input {
  font-size: 1rem;
  color: var(--font-dark);
  padding: 0;
  background: transparent;
  border: transparent;
}
